<template>
  <div class="main">
    <NavBar/>
    <!-- Loading -->
    <div v-if="loading" class="content">
      <div class="loader m-auto"></div>
      <strong v-if="isSubmitted" class="m-auto mt-8">กำลังดำเนินการ กรุณารอสักครู่</strong>
    </div>

    <!-- Content -->
    <div v-else class="content">
      <form v-on:submit.prevent="submit">
        <!-- Header -->
        <div class="row">
          <p class="project-header mb-4">แก้ไขคู่มือและเอกสารแบบฟอร์มต่าง ๆ ของรายวิชา{{ type_name }}</p>
        </div>

        <!-- Upload file -->
        <div class="form-div">  
          <p class="label my-auto -ml-4">เอกสารที่เกี่ยวข้อง</p>
          <div class="upload-button">
            <label for="file" class="file-button">อัปโหลด</label>
            <input type="file" ref="file" id="file" name="file" multiple @change="handleFileUpload($event)" class="w-0">
          </div>
        </div>

        <!-- Upload file list -->
        <div v-if="files" class="form-div">
          <p class="label my-auto -ml-4"></p>
          <div class="col-span-4">
            <ul>
              <li v-for="file in files" :key="file.id">
                <span v-on:click="removeFile(file)" class="label text-delete">ลบ</span>
                <span class="inline-block" style="min-width: 20rem">{{ file.name }}</span>
              </li>
            </ul>
          </div>
        </div>
        <hr>
        <button type="submit" value="submit" class="submit-button" :disabled="isSubmitted">บันทึกข้อมูล</button>
      </form>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar"
import { documentFileList, fileUpload } from "../mixins/other"

export default {
  name: "DocumentEdit",
  components: {
    NavBar,
  },
  data () {
    return {
      loading: true,
      isSubmitted: false,
      type_name: "",
      documents: {
        file_id: [],
        all_file_id: []
      },
      files: [],
      file_name: [],
      role: null
    }
  },
  async created() {
    this.role = this.$store.getters.payload.role
    // Permission
    if (this.role !== "STF") {
      this.$router.push("/")
    }
    this.files = await this.$store.dispatch("getDocument")
    let doc = documentFileList(this.files, this.$route.params.type_name, this.type_name)
    this.files = doc.files
    this.type_name = doc.name
    for(let f in this.files) {
      this.file_name.push(this.files[f].name)
      this.documents.file_id.push(this.files[f].id)
      this.documents.all_file_id.push(this.files[f].id)
    }
    document.title = "แก้ไขคู่มือและเอกสารรายวิชา" +  this.type_name + " | Carbon"
    this.loading = false
  },
  methods: {
    async submit() {
      if(!this.isSubmitted) {
        this.isSubmitted = true
        this.loading = true
        this.documents.file = this.files
        this.documents.file_name = this.file_name
        this.documents.type = this.$route.params.type_name
        const data = this.documents
        await this.$store.dispatch("createDocument", data)
        this.$router.push(`/document/${this.$route.params.type_name}`)
        this.isSubmitted = false
        this.loading = false
      }
    },
    handleFileUpload(event) {
      let upload = fileUpload(event, this.files, this.file_name)
      this.files = upload.files
      this.file_name = upload.file_name
    },
    removeFile(fileSelect) {
      this.files = Array.from(this.files).filter(function (file) {
        return file !== fileSelect
      })
      let index = this.file_name.indexOf(fileSelect.name)
      if (index > -1) {
        this.file_name.splice(index, 1)
        this.documents.file_id.splice(index, 1)
      }
    }
  }
}
</script>
